import React from 'react';

const ModalElementMessage = (props) => {
  return (
  <>
    <h2>message</h2>
  </> 
  
  );
};

export default ModalElementMessage;
