import React from "react";
import LogOrReg from "../components/LogOrReg"

const LoginPage = (props) => {
  return (
    <>
      {/* <p>Login Page ...</p> */}
    <LogOrReg/>
    </>
  );
};

export default LoginPage;
